import { LDFlagSet, LDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import getConfig from 'next/config'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'

interface LaunchDarklyProviderProps {
  children: ReactNode
}

interface LaunchDarklyConsumerProps {
  children: ReactNode
}

const isStageOrDevEnv = (env: string) => {
  return env === 'dev' || env === 'stage' || (env && env.startsWith('peer'))
}

const { publicRuntimeConfig } = getConfig()
const anonUser = '00000000-0000-0000-0000-000000000000'
const stageAnonHash = '97fc647bf61508ffec2fd8e56508b735b4151be3c32f194fb86fd0ee01814ae2'
const prodAnonHash = '0af75a0501aac24e83ea7784367b25625eec761e13480c08bdc0b16084f0a4af'
const anonHash = isStageOrDevEnv(publicRuntimeConfig.SERVICE_ENV) ? stageAnonHash : prodAnonHash

const ldInitializationOptions = {
  clientSideID: publicRuntimeConfig.LAUNCHDARKLY_CLIENT_SIDE_ID,
  deferInitialization: true
}

function useLDUser() {
  const anonymous = true
  const hash = anonHash

  return useMemo(() => {
    const context = {
      anonymous,
      kind: 'user',
      key: anonUser
    }

    return { context, hash }
  }, [hash, anonymous])
}


function LDHandler({ children }: LaunchDarklyConsumerProps) {
  const client = useLDClient()
  const [identifyPromise, setIdentifyPromise] = useState<Promise<LDFlagSet> | null>(null)
  const { context, hash } = useLDUser()

  useEffect(() => {
    (async() => {
      if (identifyPromise) {
        await identifyPromise
        setIdentifyPromise(null)

        return
      }

      if (client) {
        setIdentifyPromise(client.identify(context, hash))
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, hash, client])

  return (
    <>{children}</>
  )
}

export default function LaunchDarklyProvider({ children }: LaunchDarklyProviderProps): JSX.Element {
  const { context, hash } = useLDUser()

  return (
    <LDProvider {...ldInitializationOptions} options={{ hash }} context={context}>
      <LDHandler>{children}</LDHandler>
    </LDProvider>
  )
}
