import React, { VFC } from 'react'

import LaunchDarklyProvider from '../LaunchDarklyProvider/LaunchDarklyProvider'
import styles from './Layout.module.scss'

type Props = {
  children: React.ReactNode
}

const Layout: VFC<Props> = ({ children }: Props) => {
  return (
    <LaunchDarklyProvider>
      <main className={styles.main}>
        {children}
      </main>
    </LaunchDarklyProvider>
  )
}

export default Layout
