import '../scss/globals.scss'

import type { AppProps } from 'next/app'
import { VFC } from 'react'

import Layout from '../components/Layout/Layout'

const App: VFC<AppProps> = ({ Component, pageProps }: AppProps) => {
  return (
    <div data-art-theme="font-family">
      <title>Dashboard</title>
      <div id="app-modal" />

      <Layout>
        <Component {...pageProps} />
      </Layout>
    </div>
  )
}
export default App
